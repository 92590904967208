import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/Pricing/Hero';
import PricingSection from 'src/sections/Pricing/Pricing';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import PricingContactSection from 'src/sections/Pricing/Contact';
import ApiWrapper from 'src/components/ApiWrapper';

const PricingPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Pricing - Bizwise" />
      <HeroSection />
      <PricingSection />
      <FaqSection types={[FaqTypes.PRICING]} />
      <PricingContactSection showShape />
    </Layout>
  </ApiWrapper>
);

export default PricingPage;
