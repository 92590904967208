import React from 'react';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import { useSectionAboveStyles } from './Pricing';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(650),
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    marginLeft: 'auto',
    paddingLeft: spacing(6),
    maxWidth: pxToRem(580),
    [breakpoint.down('md')]: {
      paddingRight: spacing(6),
      paddingTop: spacing(2),
      marginLeft: 0,
    },
  },
  contentBackground: {
    background: 'radial-gradient(153.67% 510.76% at 153.68% 79.94%, #3D78BB 0%, #13266D 92.07%), #13296B',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(107.95deg, #13266D -12.75%, #5FBCFF 253.76%), #13296B',
    },
  },
});

const Hero: React.FC = () => {
  const overlapPaddingClasses = useSectionAboveStyles();
  const classes = useStyles();
  return (
    <SlantedSection
      img={SectionImages[14]}
      contentWidth={0.6}
      slantAmount={0.25}
      className={classes.container}
      contentClassName={classes.contentBackground}
      showNavbarGradient
    >
      <div className={cx(overlapPaddingClasses.root, classes.content)}>
        <NavbarSpacer className="hidden md:block" />
        <Typography variant="h2" color="white">
          Buy Only What Your Business Needs.
        </Typography>
        <Typography variant="p1" color="white" className="mt-2 mb-4 md:my-6" style={{ maxWidth: pxToRem(570) }}>
          Pay for only what you need through a plan and custom add-ons.
        </Typography>
      </div>
    </SlantedSection>
  );
};

export default Hero;
