import React, { useCallback, useState } from 'react';
import cx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import { usePlanDetails, useSwipable } from 'src/hooks';
import PlanCard from 'src/components/PlanCard';
import MobileTabs from 'src/components/MobileTabs';
import IconButton from 'src/components/core/IconButton';

const verticalOffset = pxToRem(200);
const mobileSliderWidth = '250vw';

export const useSectionAboveStyles = createUseStyles({
  root: {
    paddingBottom: verticalOffset,
    [breakpoint.down('md')]: {
      paddingBottom: verticalOffset,
      paddingTop: 0,
    },
  },
});

const useStyles = createUseStyles({
  container: {
    overflow: 'visible',
    // CSS trick so that we can have a video which is not absolute positioned
    // and still overlap with the above section
    paddingTop: '1px !important',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  middle: {
    marginLeft: spacing(8),
    marginRight: spacing(8),
  },
});

const PricingSection: React.FC = () => {
  const classes = useStyles();
  const plans = usePlanDetails();
  const visiblePlans = plans.filter((p) => !p.hidden);
  const [activeIdx, setActiveIdx] = useState(1);
  const {
    height: eliteDesktopHeight = 0,
    ref: eliteDesktopRef,
  } = useResizeObserver<HTMLDivElement>();
  const {
    width: cardWidth = 0,
    ref: cardRef,
  } = useResizeObserver<HTMLDivElement>();

  const {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleTouchCancel,
  } = useSwipable({
    onSwipeLeft: useCallback(() => {
      setActiveIdx((old) => Math.max(0, old - 1));
    }, []),
    onSwipeRight: useCallback(() => {
      setActiveIdx((old) => Math.min(visiblePlans.length - 1, old + 1));
    }, [visiblePlans.length]),
  });

  return (
    <SectionContainer background="light-blue" className={classes.container}>
      <div
        className="hidden overflow-hidden md:flex items-end justify-center"
        style={{
          marginTop: `-${verticalOffset}`,
          paddingBottom: spacing(12),
        }}
      >
        {visiblePlans.map((data, i) => (
          <PlanCard
            allowSelection
            key={data.planIds[0]}
            data={data}
            popular={i === 1}
            hat={i === 1 ? 'Popular' : undefined}
            ref={i === visiblePlans.length - 1 ? eliteDesktopRef : null}
            className={cx(i === 1 && classes.middle)}
            cardStyle={{
              height: i === visiblePlans.length - 1 ? undefined : eliteDesktopHeight,
            }}
          />
        ))}
      </div>
      <div className="block md:hidden overflow-hidden" style={{ marginTop: `-${verticalOffset}` }}>
        <div className="px-4">
          <MobileTabs
            options={visiblePlans.map((d) => d.title.replace(/\splan/i, ''))}
            activeIdx={activeIdx}
            onChange={(i) => setActiveIdx(i)}
          />
        </div>
        <div
          className="flex items-stretch justify-start mt-4"
          style={{
            width: mobileSliderWidth,
            transform: `translate(calc(50vw - (${cardWidth}px * ${activeIdx}) - ${pxToRem(cardWidth / 2)} - ${spacing(activeIdx * 8)}))`,
            transitionDuration: '0.5s',
          }}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchCancel}
        >
          {visiblePlans.map((data, i) => (
            <PlanCard
              allowSelection
              key={data.planIds[0]}
              data={data}
              ref={i === 1 ? cardRef : null}
              popular={i === 1}
              className={cx(i === 1 && 'mx-8')}
              cardStyle={{
                height: '100%',
              }}
            />
          ))}
        </div>
        <div className="mt-6 mb-6 flex justify-center">
          {Array.from({ length: visiblePlans.length }, (_, i) => (
            <IconButton key={i} onClick={() => setActiveIdx(i)}>
              <div
                className={cx([
                  activeIdx === i ? 'bg-teal' : 'bg-blue',
                  'rounded-[50%]',
                  'w-3',
                  'h-3',
                ])}
              />
            </IconButton>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

export default PricingSection;
